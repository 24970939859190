import styled from 'styled-components';
import Bg from './../../assets/images/app-bg-l.jpg';
// import Header from '../../views/Header';
import Main from './../../views/Main';
import Footer from './../../views/Footer';
import './home.css';

const StyledHome = styled.section`
  min-height: 100vh;  
  background-image: url(${Bg});
  background-size: cover;
`

const Home = () => {
  return (
    <StyledHome className="Home">
      {/* <Header /> */}
      <Main />
      <Footer />
    </StyledHome>
  );
}

export default Home;
