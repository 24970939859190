import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { getCampaigns } from '../../services/campaigns';
import Campaign from './../Campaign';

const StyledCampaigns = styled.section`
  margin: 0.5rem 1rem;
  padding: 0.25rem 1rem;
  width: calc(100% - 2rem);
  @media (min-width: 1025px ) {
    flex: 0 1 600px;
  }
`;

const  Campaigns = (props) => {
  const [campaigns, setCampaigns] = useState([]);
  useEffect(() => {
    let mounted = true;
    getCampaigns()
      .then(campaigns => {
        if(mounted) {
          setCampaigns(campaigns);
        }
      })
    return () => mounted = false;
  }, [])




  return (
    <StyledCampaigns 
      className="
        campaigns
      ">
       {campaigns.length > 0 &&
          campaigns.map((campaign, index) => {
            console.log("Mapping", campaign);
            console.log("Mapping name", campaign.campaign_name);

            return (
              <Campaign 
                key={campaign.campaign_id} 
                name={campaign.campaign_name} 
                num={index} 
                budget={campaign.budget}
                status={campaign.status}
                bid={campaign.bid_strategy.length > 0 ? campaign.bid_strategy : "Human readable strategy"}
                since={campaign.start_time.length > 0 ? campaign.start_time : "Date"}
                clicks={campaign.clicks ? campaign.clicks : 0}
                cpc={campaign.cpc.length > 0 ? campaign.cpc : 0}
                ctr={campaign.ctr.length > 0 ? campaign.ctr : 0}
                impressions={campaign.impressions ? campaign.impressions : 0}
                cpm={campaign.cpm.length > 0 ? campaign.cpm : 0}
                reach={campaign.reach.length > 0 ? campaign.reach : 0}
                frequency={campaign.frequency.length > 0 ? campaign.frequency : 0}
                amount_spend={campaign.amount_spent.length > 0 ? campaign.amount_spent : 0}
                conversions={campaign.conversions.length > 0 ? campaign.conversions : 0}
                cpa={campaign.cpa.length > 0 ? campaign.cpa : 0}
 
              />
            )}
          )
        }
    </StyledCampaigns>
  );
}

export default Campaigns;