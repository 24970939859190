import React, { useState } from 'react';
import styled from 'styled-components'

import Button from './../Button';
import Facebook from './../../assets/images/facebook.png';
import Google from './../../assets/images/google.png';
import Finland from './../../assets/images/finland.svg';
import Europe from './../../assets/images/europe.svg';
import World from './../../assets/images/world.svg';
import Chevron from "./Chevron";

// import Select from './../Select';

const PlatformsMap = {
  "Facebook": Facebook,
  "Google": Google
}

const StyledForm = styled.form`
  /* padding: 0.75em 1em 1.25em; */
  padding: 0;
  border: 2px dashed rgba(255,250,200, 0.7);
  border-radius: 2rem;
  background-color: #ee06b3;
  .form-header {
    color: white;
    /* padding-bottom: 1rem; */
    padding: 0.75rem 1rem 1.25rem;
    /* border-bottom: 1px solid white; */
    text-align: left;
    font-size: 1.2rem;
    font-weight: 700;
    img { width: 32px; display: inline-block; margin-right: 1rem; }
  }
  section {
    /* border-bottom: 1px solid white; */
  }
  .section-header {
    position: relative;
    color: white;
    margin:1px;
    padding: 0.5rem 1rem .5rem;
    border-top: 1px solid rgba(255,250,200, 0.7);
    cursor: pointer;
    text-align: left;
    .section-name { text-transform: uppercase; }
    .chevron {
      position: absolute;
      top: 1rem;
      right: 1rem;
      transform: rotate(90deg);
      transition: transform 0.3s;
    }
    &.content-shown {
      outline: 2px solid rgba(255,250,200, 0.7);
      background-color: #fbb03b;
      color: black;
      .chevron { transform: rotate(-90deg); }
    }
    .section-preview {
      font-style: italic;
    }
  }
  .section-header > * {
    pointer-events: none;
  } 
  .section-content {
    display: none;
    padding: 1rem 0;
  }
  .form-tools-bottom {
    padding: 1rem;
    border-top: 1px solid rgba(255,250,200, 0.7);
  }
  .form-row {
    position: relative;
    /* margin: 10px auto; */
    padding: 0.5rem 1rem .5rem;
    text-align:left;
    label {
      /* position: absolute;
      left: 2rem;
      top: 0.5em; */
      display:block;
      color: white;
      margin-left:1rem;
      margin-bottom: 0.2rem;
      &.inline-label {
        display: inline;
        margin-left:1ch;
      }
    }
    input, select {
      width: 100%;
      background: white;
      border-radius: 2rem;
      padding: 0.4em 2rem;
    }
    input[type="radio"] {
      display: inline-block;
      width: auto;
    }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 5px;
      margin-right: 2rem;
    }
    .budget {
      width: 40%;
    }
    .budget-options, .schedule-options {
      width: 100%;
      margin-left: 15px;
    }
    .currency {
      color: white;
      font-size: 2rem;
      vertical-align: middle;
      padding-left: 0.3em;
    }
    .lifetime-end-picker {
      display: inline-block;
      max-width: 200px;
      margin-left: 6px;
    }
    .schedule-start-picker, .schedule-end-picker,.age-start-picker, .age-end-picker  {
      display: inline-block;
      width: 45%;
    }
    .line {
      padding: 0 1ch;
      color: white;
    }
    .switch-field {
      display: flex;
      margin-bottom: 36px;
      overflow: hidden;
    }

    .switch-field input {
      position: absolute !important;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      width: 1px;
      border: 0;
      overflow: hidden;
    }

    .switch-field label {
      flex: 1;
      background-color: rgba(238,6,179,05);
      color: white;
      font-size: 14px;
      line-height: 1;
      text-align: center;
      padding: 12px 16px;
      margin-right: -1px;
      margin-left: 0;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
      transition: all 0.1s ease-in-out;
    }

    .switch-field label:hover {
      cursor: pointer;
    }

    .switch-field input:checked + label {
      background-color: white;
      color: black;
      box-shadow: none;
    }

    .switch-field label:first-of-type {
      border-radius: 2rem 0 0 2rem;
    }

    .switch-field label:last-of-type {
      border-radius: 0 2rem 2rem 0;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      .tag {
        padding: 0.5rem;
        background-color: transparent;
        border: 1px solid #ccc;
        border-radius: 2rem;
        color: #ccc;
        margin: 0.2rem;
        text-transform: capitalize;
        &.selected {
          background-color: #EE06B3;
          color: white;
          border: 1px solid #fff;
        } 
      }
    }
    .disabled {
      opacity: 0.75;

    }
  }
`;
const StyledMap = styled.img`
  width: 100px;
  margin-top: 1rem;
`
const Form = (props) => {

  let platformName = props.platform;
  let PlatformLogo = PlatformsMap[platformName];

  
  var someDate = new Date();
  someDate.setDate(someDate.getDate() + 1);
  var tomorrow = someDate.toISOString().substr(0, 10);

  const [name, setName] = useState("Our Campaign");
  const [status, setStatus] = useState("draft");
  const [traffic, setTraffic] = useState("traffic");
  // const [targeting, setTargeting] = useState("location");
  const [country, setCountry] = useState("finland");
  const [gender, setGender] = useState("Both");
  const [age_groups, setAgeGroups] = useState("18-65");
  const [interests, setIntrests] = useState([]);
  const [placement, setPlacement] = useState("placement");
  const [budget, setBudget] = useState(10);
  const [daily_budget, setDailyBudget] = useState(10);
  // const [is_daily_budget, setIsDailyBudget] = useState(true);
  const [lifetime_budget, setLifetimeBudget] = useState();
  // const [is_lifetime_budget, setIsLifetimeBudget] = useState(false);
  const [ongoing, setOngoing] = useState(true);
  const [timed, setTimed] = useState(false);
  const [schedule_type, setScheduleType] = useState("ongoing");
  const [start_time, setStartTime] = useState(tomorrow);
  const [end_time, setEndTime] = useState(tomorrow);
  const [start_age, setStartAge] = useState(18);
  const [end_age, setEndAge] = useState(99);
  const [headline_1, setHeadline1] = useState("Headline 1");
  const [headline_2, setHeadline2] = useState();
  const [headline_3, setHeadline3] = useState();
  const [primary_text, setPrimaryText] = useState("Primary text");
  const [description, setDescription] = useState("Description");
  const [description_2, setDescription2] = useState();
  const [destination_url, setDestinationUrl] = useState("https://www.kumpulantoimisto.fi/");
  const [media_url, setMediaUrl] = useState("https://via.placeholder.com/468x60?text=468x60+Full +Banner");
  const [cta, setCta] = useState("Learn More");
  const [isChecked, setIsChecked] = useState(true);

  const [state, changeState] = useState({
    "campaign_name":name,
    "campaign_id":"23846794546350134",
    "budget":budget,
    "bid_strategy":"LOWEST_COST_WITHOUT_CAP",
    "status":status,
    "start_time":start_time,
    "schedule_type":schedule_type,
    "ongoing": ongoing,
    "end_time":end_time,
    "start_age":start_age,
    "end_age":end_age,
    "platform": platformName,
    "daily_budget":daily_budget,
    "lifetime_budget":lifetime_budget,
    "destination_type":destination_url,
    "is_dynamic_creative":false,
    "lifetime_imps":0,
    "age_groups": age_groups,
    "country": country,
    "gender": gender,
    "interests":interests,
    "placement":placement,
    "headline_1":headline_1,
    "headline_2":headline_2,
    "headline_3":headline_3,
    "primary_text":primary_text,
    "description":description,
    "description_2":description_2,
    "destination_url":destination_url,
    "media_url":media_url,
    "cta":cta,
  });
  // console.log("PUT API values: ", state);

  const handleSection = (event) => {
    console.log("Section target: ", event.target);
    console.log("Section next: ", event.target.nextSibling);
    const sectionContent = event.target.nextElementSibling;
    if (sectionContent.style.display === 'block') {
      sectionContent.style.display = 'none';
      event.target.classList.remove('content-shown');
      event.target.classList.add('content-hidden');
      event.target.setAttribute('aria-expanded','false');
    } else {
      sectionContent.style.display = 'block';
      event.target.classList.add('content-shown');
      event.target.classList.remove('content-hidden');
      event.target.setAttribute('aria-expanded','true');
    }
  }

  const handleInterests = (event, interest) => {
    console.log('event:', event.target );
    setIsChecked(!isChecked);
    
    console.log('isChecked:', isChecked );

    let tmp = state.interests;
    if (state.interests.includes(interest)) {
      const arr = [...state.interests];
      const index = arr.indexOf(interest);
      if (index !== -1) {
        arr.splice(index, 1);
        changeState(state => ({ ...state, interests: arr }));
        event.target.classList.remove('selected');
      }
  } else {
      tmp.push(interest);
      changeState(state => ({ ...state, interests: tmp }));
      event.target.classList.add('selected');
  }
    console.log('New interests:', state.interests );
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitted:', state );
  }



  return (
    <StyledForm>
      <div className="form-row form-header">
        {PlatformLogo && <img src={PlatformLogo} alt={`${props.platform} logo`} />}
        <span>{props.platform}</span>
      </div>
      <hr />
      <div className="form-row">
        <h2 className="text-white text-xl text-left">Create Campaign</h2>
      </div>
        <div className="form-row">
          <label>Campaign name</label>
          <input 
            type="text" 
            name="name" 
            value={state.campaign_name}
            // onChange={e => setName(e.target.value)}
            onChange={event => { 
              changeState(state => ({ ...state, campaign_name: event.target.value })); 
              }
            }
            required 
          />
        </div>
        <div className="form-row">
          <label>Status</label>
          <select 
            name="status" 
            id="status" 
            value={state.status} 
            // onChange={e => setStatus(e.target.value)} 
            onChange={event => { 
              changeState(state => ({ ...state, status: event.target.value })); 
              }
            }
            required
          >
            <option value="draft">Draft</option>
            <option value="running">Running</option>
            <option value="ended">Ended </option>
          </select>
        </div>
        <br />
        <section>
          <div className="section-header" onClick={event => handleSection(event)}>
            <h3 className="section-name">Targeting</h3>
            <Chevron className={`chevron`} width={10} fill={"#fff"} />
            <div className="section-preview">
            <span>Objective {state.traffic}, </span>
            <span>Locations {state.country}, </span>
            <span>Genders {state.gender}, </span>
            <span>Ages {state.start_age} - {state.start_age} </span>
            </div>
          </div>
          <div className="section-content">
            <div className="form-row">
              <label htmlFor="objective" className="label-above">Objective</label>
              <select 
                name="objective" 
                id="objective" 
                value={traffic} 
                // onChange={e => setTraffic(e.target.value)} 
                onChange={event => { 
                  changeState(state => ({ ...state, traffic: event.target.value })); 
                  }
                }
                required
              >
                <option value="traffic">Traffic</option>
                <option value="reach">Reach</option>
                <option value="conversions">Conversions </option>
              </select>
            </div>

            <div className="form-row">
              <label>Location targeting</label>
              <div className="switch-field">
                <input 
                  type="radio" 
                  id="finland" 
                  name="country" 
                  value="finland" 
                  defaultChecked 
                  // onChange={e => setOngoing(e.target.value)} 
                  onChange={event => { 
                    changeState(state => ({ ...state, country: event.target.value })); 
                    }
                  }
                />
                <label className="toggle-radio" htmlFor="finland" >
                  Finland
                  <StyledMap src={Finland} alt="Finland" /> 
                </label>
                  
                <input 
                  type="radio" 
                  id="europe" 
                  name="country" 
                  value="europe"
                  // onChange={e => setOngoing(e.target.value)} 
                  onChange={event => { 
                    changeState(state => ({ ...state, country: event.target.value })); 
                    }
                  }
                  />
                  <label className="toggle-radio" htmlFor="europe" >
                    Europe
                    <StyledMap src={Europe} alt="europe" /> 
                  </label>
                
                <input 
                  type="radio" 
                  id="world" 
                  name="country" 
                  value="world"
                  // onChange={e => setOngoing(e.target.value)} 
                  onChange={event => { 
                    changeState(state => ({ ...state, country: event.target.value })); 
                    }
                  }
                  />
                  <label className="toggle-radio" htmlFor="world" >
                    World
                    <StyledMap src={World} alt="world" /> 
                  </label>
                </div>
                
            </div>
            <div className="form-row">
              <label>Gender targeting</label>
                <div className="switch-field">
                  <input 
                    type="radio" 
                    id="all" 
                    name="gender" 
                    value="All" 
                    defaultChecked 
                    // onChange={e => setOngoing(e.target.value)} 
                    onChange={event => { 
                      changeState(state => ({ ...state, gender: event.target.value })); 
                      }
                    }
                  />
                  <label className="toggle-radio" htmlFor="all" >All</label>
                  
                  <input 
                    type="radio" 
                    id="male" 
                    name="gender" 
                    value="Male"
                    // onChange={e => setOngoing(e.target.value)} 
                    onChange={event => { 
                      changeState(state => ({ ...state, gender: event.target.value })); 
                      }
                    }
                  />
                  <label className="toggle-radio" htmlFor="male" >Male</label>
                    
                  <input 
                    type="radio" 
                    id="female" 
                    name="gender" 
                    value="Female"
                    // onChange={e => setOngoing(e.target.value)} 
                    onChange={event => { 
                      changeState(state => ({ ...state, gender: event.target.value })); 
                      }
                    }
                  />
                  <label className="toggle-radio" htmlFor="female" >Female</label>
                  
                  <input 
                    type="radio" 
                    id="other" 
                    name="gender" 
                    value="Other"
                    // onChange={e => setOngoing(e.target.value)} 
                    onChange={event => { 
                      changeState(state => ({ ...state, gender: event.target.value })); 
                      }
                    }
                  />
                  <label className="toggle-radio" htmlFor="other" >Other</label>
                </div>
            </div>

            <div className="form-row">
              {/* <label>Age groups targeting</label> */}

              <div>
              <label>Age min - max</label>
                <input 
                  type="number" 
                  id="age_start" 
                  name="age_start"
                  className="age-start-picker"
                  value={state.start_age} 
                  // onChange={e => setStartTime(e.target.value)} 
                  onChange={event => { 
                    changeState(state => ({ ...state, start_age: event.target.value })); 
                    }
                  }
                /> 
                <span className="line">-</span>
                <input 
                  type="number" 
                  id="age_end" 
                  name="age_end"
                  className="age-end-picker"
                  value={state.end_age} 
                  // onChange={e => setEndTime(e.target.value)} 
                  onChange={event => { 
                    changeState(state => ({ ...state, end_age: event.target.value })); 
                    }
                  }
                />
              </div>
            </div>
            
            <div className="form-row">
              <label>Special interest targeting</label>
              <div className="tags">
                <div className="tag" onClick={(event) => handleInterests(event, "entrepreneurship" ) }>entrepreneurship</div>
                <div className="tag" onClick={(event) => handleInterests(event, "online shopping" ) }>online shopping</div>
                <div className="tag" onClick={(event) => handleInterests(event, "travelling" ) }>travelling</div>
                <div className="tag" onClick={(event) => handleInterests(event, "ice hockey" ) }>ice hockey</div>
                <div className="tag" onClick={(event) => handleInterests(event, "physical fitness" ) }>physical fitness</div>
                <div className="tag" onClick={(event) => handleInterests(event, "sustainable development" ) }>sustainable development</div>
                <div className="tag" onClick={(event) => handleInterests(event, "horseback riding" ) }>horseback riding</div>
                <div className="tag" onClick={(event) => handleInterests(event, "classical music" ) }>classical music</div>
                <div className="tag" onClick={(event) => handleInterests(event, "single-family detached house" ) }>single-family detached house</div>
                <div className="tag" onClick={(event) => handleInterests(event, "academic degree" ) }>academic degree</div>
                <div className="tag" onClick={(event) => handleInterests(event, "tesla motors" ) }>tesla motors</div>
              </div>
            </div>
          </div>
        </section>
        
        <section>
          <div className="section-header" onClick={event => handleSection(event)}>
            <h3 className="section-name">Budget</h3>
            <Chevron className={`chevron`} width={10} fill={"#fff"} />
            <div className="section-preview">
            <span>{state.daily_budget > 0 ? "Daily" : "lifetime"} </span>
            <span>{state.daily_budget > 0 ? state.daily_budget : state.lifetime_budget}€ </span>
            {state.lifetime_budget > 0 &&
              <span>
                <span>Until </span>
                <span>{state.end_time} </span>
              </span>

            }
            </div>
          </div>
          <div className="section-content">
            <div className="form-row">
              <label className={`${state.lifetime_budget ? "disabled" : ""} `} >Daily Budget €</label>
              <input 
                type="number" 
                className={`inline-block budget ${state.lifetime_budget ? "disabled" : ""} `} 
                name="Budget" 
                value={state.daily_budget} 
                // onChange={e => setDailyBudget(e.target.value)} 
                onChange={event => { 
                  changeState(state => ({ ...state, daily_budget: event.target.value })); 
                  }
                }
                disabled={state.lifetime_budget ? true : false} 
              />
              <span 
                className={`currency ${state.lifetime_budget ? "disabled" : ""} `} 
              >€</span>

              <label className={`${state.daily_budget ? "disabled" : ""} `}>Or <br />Lifetime Budget €</label>
              <input 
                type="number" 
                className={`inline-block budget ${state.daily_budget ? "disabled" : ""} `} 
                name="lifetime-udget" 
                value={status.lifetime_budget} 
                // onChange={e => setLifetimeBudget(e.target.value)} 
                onChange={event => { 
                  changeState(state => ({ ...state, lifetime_budget: event.target.value })); 
                  }
                }
                disabled={state.daily_budget ? true : false} 
              />
              <span 
                className={`currency ${state.daily_budget ? "disabled" : ""} `} 
              >€</span>

              <div className="budget-options">
                
                {state.lifetime_budget > 0 && <div><label htmlFor="lifetime_end"className="inline-label">Until:</label>
                <input 
                  type="date" 
                  id="lifetime_end" 
                  name="lifetime_end"
                  className="lifetime-end-picker"
                  value={state.end_time}
                /></div>}
              </div>
            </div>

          </div>
        </section>
        <section>
          <div className="section-header" onClick={event => handleSection(event)}>
            <h3 className="section-name">Schedule</h3>
            <Chevron className={`chevron`} width={10} fill={"#fff"} />
            <div className="section-preview">
            <span>{state.schedule_type === "ongoing" ? "Ongoing" : "Period"} </span>
            {state.schedule_type === "timed" &&
              <span>
                <span>{state.start_time} - </span>
                <span>{state.end_time} </span>
              </span>
            }
            </div>
          </div>
          <div className="section-content">
              <div className="form-row">
              <label>Schedule</label>
              <div className="schedule-options text-white">
                <input 
                  type="radio" 
                  id="schedule" 
                  name="budget_type" 
                  value={state.ongoing} 
                  // onChange={e => setOngoing(e.target.value)} 
                  defaultChecked 
                  onClick={event => { 
                    changeState(state => ({ ...state, schedule_type: "ongoing"})); 
                    }
                  }
                />
                <label className="inline-label" >OnGoing</label>
                <br />
                <input 
                  type="radio" 
                  id="schedule" 
                  name="budget_type" 
                  value={state.timed} 
                  // onChange={e => setOngoing(e.target.value)} 
                  onClick={event => { 
                    changeState(state => ({ ...state, schedule_type: "timed"})); 
                    }
                  }
                />
                <label className="inline-label">Period:</label>

                  <div>
                    <input 
                      type="date" 
                      id="schedule_start" 
                      name="schedule_start"
                      className={`text-black schedule-start-picker ${state.schedule_type === "ongoing" ? "disabled" : ""} `} 
                      value={state.start_time} 
                      // onChange={e => setStartTime(e.target.value)} 
                      onChange={event => { 
                        changeState(state => ({ ...state, start_time: event.target.value })); 
                        }
                      }
                    />
                    <span className="line">-</span>
                    <input 
                      type="date" 
                      id="schedule_end" 
                      name="schedule_end"
                      className={`text-black schedule-end-picker ${state.schedule_type === "ongoing" ? "disabled" : ""} `} 
                      value={state.end_time} 
                      // onChange={e => setEndTime(e.target.value)} 
                      onChange={event => { 
                        changeState(state => ({ ...state, end_time: event.target.value })); 
                        }
                      }
                    />
                  </div>

                
              </div>
            </div>
          </div>
        </section>
        
        <section>
          <div className="section-header" onClick={event => handleSection(event)}>
            <h3 className="section-name">Advertisement</h3>
            <Chevron className={`chevron`} width={10} fill={"#fff"} />
            <div className="section-preview">
              <span>{state.headline_1}, </span>
              <span>{state.headline_2 ? `${state.headline_2},` : ""} </span>
              <span>{state.headline_3 ? `${state.headline_3},` : ""} </span>
              <span>{state.primary_text}, </span>
              <span>{state.description}, </span>
              <span>{state.description_2 ? `${state.description_2},` : ""} </span>
              <span>{state.destination_url ? `Target ${state.destination_url},` : ""} </span>
              {/* <span>{state.media_url ? `${state.media_url},` : ""} </span> */}
              <img src={state.media_url} alt="ad" style={{width: '100%', margin: '1rem 0'}} />
            </div>
          </div>
          <div className="section-content">
            <div className="form-row">
              <label>Placement</label>
              <select 
                name="placement" 
                id="placement" 
                value={state.placement} 
                // onChange={e => setPlacement(e.target.value)} 
                onChange={event => { 
                  changeState(state => ({ ...state, placement: event.target.value })); 
                  }
                }
                required
              >
                {platformName === 'Google' &&
                <>
                <option value="fb_feed">Google hakumainokset</option>
                </>
                }
                {platformName === 'Facebook' &&
                <>
                <option value="fb_feed">Facebook feed</option>
                <option value="fb_right_hand">Facebook right hand</option>
                </>
                }
              </select>
            </div>
            <div className="form-row">
              <label>Headline</label>
              <input 
                type="text" 
                name="headline" 
                value={state.headline_1} 
                // onChange={e => setHeadline1(e.target.value)} 
                onChange={event => { 
                  changeState(state => ({ ...state, headline_1: event.target.value })); 
                  }
                }
                required 
              />
            </div>
            {platformName === 'Google' && <div className="form-row">
              <label>Headline 2</label>
              <input 
                type="text" 
                name="headline_2" 
                value={state.headline_2} 
                // onChange={e => setHeadline2(e.target.value)} 
                onChange={event => { 
                  changeState(state => ({ ...state, headline_2: event.target.value })); 
                  }
                }
              />
            </div>}
            {platformName === 'Google' && <div className="form-row">
              <label>Headline 3</label>
              <input 
                type="text" 
                name="headline_3" 
                value={state.headline_3} 
                // onChange={e => setHeadline3(e.target.value)} 
                onChange={event => { 
                  changeState(state => ({ ...state, headline_3: event.target.value })); 
                  }
                }
              />
            </div>}

            {platformName === 'Facebook' && <div className="form-row">
              <label>Text content</label>
              <input 
                type="text" 
                name="description" 
                value={state.primary_text} 
                // onChange={e => setPrimaryText(e.target.value)} 
                onChange={event => { 
                  changeState(state => ({ ...state, primary_text: event.target.value })); 
                  }
                }
              />
            </div>}

            
            {platformName === 'Google' && <div className="form-row">
              <label>Description</label>
              <input 
                type="text" 
                name="description_1" 
                value={state.description} 
                // onChange={e => setDescription(e.target.value)} 
                onChange={event => { 
                  changeState(state => ({ ...state, description: event.target.value })); 
                  }
                }
              />
            </div>}
            {platformName === 'Google' && <div className="form-row">
              <label>Description 2</label>
              <input 
                type="text" 
                name="description_2" 
                value={state.description_2} 
                // onChange={e => setDescription2(e.target.value)} 
                onChange={event => { 
                  changeState(state => ({ ...state, description_2: event.target.value })); 
                  }
                }
              />
            </div>}

            <div className="form-row">
              <label>Destination URL</label>
              <input 
                type="url" 
                name="destination" 
                value={state.destination_url} 
                // onChange={e => setDestinationUrl(e.target.value)} 
                onChange={event => { 
                  changeState(state => ({ ...state, destination_url: event.target.value })); 
                  }
                }
                required 
              />
            </div>

            {platformName === 'Facebook' && <div className="form-row">
              <label>Media upload URL</label>
              <input 
                type="url" 
                name="media" 
                value={state.media_url} 
                onChange={event => { 
                  changeState(state => ({ ...state, media_url: event.target.value })); 
                  }
                } 
              />
              <img src={state.media_url} alt="ad" style={{width: '100%', margin: '1rem 0'}} />
            </div>}

            {platformName === 'Facebook' && <div className="form-row">
              <input 
                type="radio" 
                id="learn-more" 
                name="cta" 
                value={state.cta} 
                // onChange={e => setCta(e.target.value)} 
                onClick={event => { 
                  changeState(state => ({ ...state, cta: "Learn more" })); 
                  }
                }
              />
              <label className="inline-label">CTA Learn more</label>
              <br />
              <input 
                type="radio" 
                id="shop-now" 
                name="cta" 
                value={state.cta} 
                // onChange={e => setCta(e.target.value)} 
                onClick={event => { 
                  changeState(state => ({ ...state, cta: "Shop now" })); 
                  }
                }
              />
              <label className="inline-label">CTA Shop now</label>
            </div>}

          </div>
        </section>


        <div className="form-tools-bottom">
          <Button name="Cancel" alert />
          <Button name="Save Draft" />
          <Button name="Publish Campaign" onClick={handleSubmit} />
        </div>

      
    </StyledForm>
  )
}
export default Form;