import React, { useState, useRef } from "react";
import Chevron from "./Chevron";
import Button from './../Button';

import "./accordion.css";

function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const d = new Date(props.since);
  const sinceDate = d.toLocaleDateString();

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight + 100}px`
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }

  return (
    <div className="accordion__section" key={props.id}>
      <button className={`accordion__button ${setActive}`} onClick={toggleAccordion} tabIndex="0">
        <p className="accordion__title">{props.name}</p>
        <Chevron className={`${setRotate}`} width={10} fill={"#fff"} />
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <div className="accordion__text">
          <div className="accordion__score score">
            <p  className="accordion__meter">Status</p>
            <p className="accordion__string">{props.status}</p>
          </div>

          <div className="accordion__score score ">
            <p  className="accordion__meter">Budget</p>
            <p className="accordion__number">{props.budget}</p>
          </div>
          <div className="accordion__score score">
            <p  className="accordion__meter ">Strategy</p>
            <p className="accordion__string" style={{fontSize: '12px'}}>{props.bid}</p>
          </div>
          <div className="accordion__score score ">
            <p  className="accordion__meter">Since</p>
            <p className="accordion__date">
              {sinceDate}</p>
          </div>
          <div className="accordion__score score">
            <p  className="accordion__meter">Clicks</p>
            <p className="accordion__number">{props.clicks}</p>
          </div>
          <div className="accordion__score score">
            <p  className="accordion__meter">CPC</p>
            <p className="accordion__number">{props.cpc}</p>
          </div>
          <div className="accordion__score score">
            <p  className="accordion__meter">CTR</p>
            <p className="accordion__number">{props.ctr}</p>
          </div>
          <div className="accordion__score score">
            <p  className="accordion__meter">Impressions</p>
            <p className="accordion__number">{props.impressions}</p>
          </div>
          <div className="accordion__score score">
            <p  className="accordion__meter">CPM</p>
            <p className="accordion__number">{props.cpm}</p>
          </div>
          <div className="accordion__score score">
            <p  className="accordion__meter">Reach</p>
            <p className="accordion__number">{props.reach}</p>
          </div>
          <div className="accordion__score score">
            <p  className="accordion__meter">Frequency</p>
            <p className="accordion__number">{props.frequency}</p>
          </div>
          <div className="accordion__score score">
            <p  className="accordion__meter">Amount spend</p>
            <p className="accordion__number">{props.amount_spend}</p>
          </div>
          <div className="accordion__score score">
            <p  className="accordion__meter">Conversions</p>
            <p className="accordion__number">{props.conversions}</p>
          </div>
          <div className="accordion__score score">
            <p  className="accordion__meter">CPA</p>
            <p className="accordion__number">{props.cpa}</p>
          </div>
        </div>

        <div className="accordion-footer p-4">
          <Button name="Edit campaign" />
        </div>
      </div>
    </div>
  );
}

export default Accordion;
