import React from 'react';
import styled from 'styled-components'

const StyledPlus = styled.button`
  background: #EE06B3;
  border-radius: 50%;
  border: 2px solid rgba(255,250,200, 0.7);
  color: white;
  margin: 1rem auto 0.25rem;
  width: 150px;
  height: 150px;
  font-size: 5rem;
  font-weight: 900;
`;

const  Plus = (props) => {
  return (
    <React.Fragment>
      <StyledPlus 
        className="
          button
        ">
        +
      </StyledPlus>
      <p className="text-center text-white">{props.name}</p>
    </React.Fragment>
  );
}

export default Plus;
